<template>
  <div v-if="questions.length">
    <vue-final-modal
      v-model="showModal"
      classes="modal-container"
      content-class="modal-content w-600"
    >
      <div class="modal-title">
        <div class="modal-title-left">
          <img src="/images/close-icon.png" @click="toggleModal()"/>
        </div>
        <h3>{{$t('quiz.quiz')}}</h3>
        <div class="modal-title-right"/>
      </div>
      <div class="modal-body quiz">
        <div class="quiz__nav">
          <div class="quiz__index" v-for="(q, index) in questions" :key="q.id" :class="{ active: index === selectedIndex }" @click="navigate(index)">
            <img src="/images/marker-red.png" v-if="index === selectedIndex"/>
            <img src="/images/marker-gray.png" v-else/>
            <span>{{(index + 1)}}</span>
            <strong/>
          </div>
          <div class="quiz__line" :style="{ width: ((questions.length - 1) * 45) + 'px'  }"/>
        </div>
        <div class="mg-t-20 pd-20" v-if="isFreeText">
          <h3>{{questionTitle}}</h3>
          <div class="quiz__answers mg-t-20">
            <div class="form-element">
              <textarea v-model="questions[selectedIndex].selected_answer" class="form-control gray-border"/>
            </div>
          </div>
        </div>
        <div class="mg-t-20 pd-20" v-else>
          <h3>{{questionTitle}}</h3>
          <div class="quiz__answers mg-t-20">
            <div v-for="(option, index) in options" :key="index" @click="selectAnswer(index)" class="quiz__answer mg-b-10" :class="{
              selected: isSelected(index),
              success: isSuccess(index),
              error: isError(index)
            }">
              <strong>{{(index + 1)}}</strong>
              <span>{{option.name}}</span>
              <div class="quiz__indication" v-if="isValidated">
                <img src="/images/success.png" v-if="isSuccess(index)"/>
                <img src="/images/error.png" v-else-if="isError(index)"/>
              </div>
            </div>
          </div>
        </div>
        <div class="mg-t-20 pd-20">
          <button class="btn btn--green blocked" @click="next" :class="{ disabled: !hasAnswer }" :disabled="!hasAnswer">{{$t('quiz.next')}}</button>
        </div>
        <Loading v-if="isLoading"/>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Loading from '@/components/loading/Loading'
import { Submissions } from '@/api/api'

export default {
  name: 'Quiz',
  components: { Loading },
  props: [
    'show',
    'quiz'
  ],
  data () {
    return {
      showModal: false,
      isLoading: false,
      questions: [],
      selectedIndex: 0
    }
  },
  computed: {
    ...mapGetters({
      language: 'auth/language'
    }),
    isFreeText () {
      return this.question?.is_free_text
    },
    question () {
      return this.questions[this.selectedIndex]
    },
    questionTitle () {
      return this.question?.question[this.language] || this.question?.question[Object.keys(this.question?.question)[0]]
    },
    options () {
      const answers = this.question?.answers[this.language] || this.question?.answers[Object.keys(this.question?.answers)[0]]
      return answers
    },
    hasAnswer () {
      return this.question.selected_answer !== undefined
    },
    isValidated () {
      return this.question.is_validated
    }
  },
  mounted () {
    if (this.quiz) {
      let hasTeacherQuestion = false

      this.questions = this.quiz.questions.map((q) => {
        for (const lang in q.answers) {
          if (Object.keys(q.answers).includes(lang) && typeof q.answers[lang] === 'string') {
            q.answers[lang] = JSON.parse(q.answers[lang])
          }
        }

        if (q.submissions[0]) {
          q.selected_answer = q.is_free_text ? q.submissions[0].answer : parseInt(q.submissions[0].answer)
          q.is_validated = true
        }

        q.new_submit = false

        if (q.user_id) {
          hasTeacherQuestion = true
        }

        return q
      }).filter((q) => {
        if (q.is_free_text && hasTeacherQuestion && !q.user_id) {
          return false
        }

        return true
      })
    }
  },
  methods: {
    navigate (index) {
      this.selectedIndex = index
    },
    toggleModal () {
      this.showModal = !this.showModal

      this.$emit('toggle', this.showModal)
    },
    selectAnswer (index) {
      if (this.isValidated && this.question.selected_answer === (this.question.correct_answer - 1)) {
        return
      }
      this.questions[this.selectedIndex].is_validated = false
      this.questions[this.selectedIndex].selected_answer = index
    },
    isSelected (index) {
      return !this.isValidated && index === this.question.selected_answer
    },
    isError (index) {
      return this.isValidated && index !== (this.question.correct_answer - 1) && index === this.question.selected_answer
    },
    isSuccess (index) {
      return this.isValidated && index === (this.question.correct_answer - 1) && index === this.question.selected_answer
    },
    next () {
      if (this.hasAnswer) {
        this.questions[this.selectedIndex].is_validated = true
      }

      if (!this.isFreeText && this.isError(this.question.selected_answer)) {
        return
      }

      if (this.selectedIndex < this.questions.length - 1) {
        this.selectedIndex += 1
      } else {
        this.saveAnswers()
      }
    },
    async saveAnswers () {
      this.isLoading = true

      try {
        const answers = []

        this.questions.forEach((q) => {
          if (q.is_validated && !q.submissions.length && !q.new_submit) {
            q.new_submit = true
            console.log(q.new_submit)
            answers.push({
              question_id: q.id,
              answer: q.selected_answer,
              questionnaire_id: q.questionnaire_id
            })
          }
        })

        const resp = await Submissions.create(answers)

        this.$store.dispatch('auth/addPoints', {
          points: resp.data.points
        })
        this.$emit('completed', resp.data.points)
      } catch (err) {
        this.$toast.error(this.$t('quiz.save_failed'))
      } finally {
        this.isLoading = false
      }
    }
  },
  watch: {
    show () {
      this.showModal = this.show
    },
    quiz () {
      this.questions = this.quiz.questions.map((q) => {
        for (const lang in q.answers) {
          if (Object.keys(q.answers).includes(lang)) {
            q.answers[lang] = JSON.parse(q.answers[lang])
          }
        }

        if (q.submissions[0]) {
          q.selected_answer = q.is_free_text ? q.submissions[0].answer : parseInt(q.submissions[0].answer)
          q.is_validated = true
        }

        return q
      })
    }
  }
}
</script>

<style scoped lang="scss">
  @import "style";
</style>
